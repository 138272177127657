import { goodsSkuPage } from "@/api/goods/goods"
import { tree } from "@/api/goods/goodscategory"

export default {
    name: "hugeDeals",
    components: {

    },
    data: () => {
        return {
            currentPage: 1,
            pageSize: 20,
            total: 0,
            categoryShow: 0,
            order: "sale_num",
            sort: "",
            categoryId: 0,
            hugeDealsList: [],
            strCurXSoyego: localStorage.getItem("strCurXSoyego"),
            countrySoyego: localStorage.getItem("countrySoyego"),
            loading: true,
            value: "Recommend",
            options: [
                {
                    label: "Recommend",
                    value: 0
                }, {
                    label: "Price Low To High",
                    value: 1
                }, {
                    label: "Price High To Low",
                    value: 2
                }
            ],
            classifyList: [],
            slectedId: ''
        }
    },
    watch: {

    },
    created() {
        this.getTree();
        this.getHugeDealsList();
    },
    mounted() {

    },
    computed: {

    },
    methods: {
        getHugeDealsList() {
            const params = {
                page: this.currentPage,
                page_size: this.pageSize,
                category_id: this.categoryId,
                order: this.order,
                sort: this.sort
            }
            goodsSkuPage(params || {}).then(res => {
                if (res.code == 0) {
                    this.total = res.data.count;
                    // let j = 0;
                    // console.log(res.data.list)
                    res.data.list.forEach(item => {
                        // console.log(item)
                        item.category_id = item.category_id.split(',');
                        item.category_name = item.category_name.split('/');
                        item.goods_image = item.goods_image.split(',');
                        item.goods_spec_format = JSON.parse(item.goods_spec_format);
                        if (item.goods_spec_format != null) {
                            this.$set(item.goods_spec_format[0], 'colorSoyego', item.goods_spec_format[0].value[0].sku_id);
                        }
                    });
                    this.hugeDealsList = res.data.list;
                    this.loading = false;
                }
            }).catch(err => {
                this.loading = false
            })
        },
        getTree() {
            tree({
                level: 1
            }).then((res) => {
                if (res.code == 0 && res.data) {
                    this.classifyList = res.data;
                }
            }).catch((err) => {
                this.$message.error(err.message)
            })
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.categoryShow = 1;
            this.loading = true;
            this.getHugeDealsList();
        },
        selectChange() {
            this.categoryShow = 1;
            this.loading = true;
            if (this.value == 1) {
                this.order = "discount_price";
                this.sort = "asc";
                this.getHugeDealsList();
            } else if (this.value == 2) {
                this.order = "discount_price";
                this.sort = "desc";
                this.getHugeDealsList();
            } else if (this.value == 0) {
                this.order = "sale_num";
                this.sort = "";
                this.getHugeDealsList();
            }
        },
        categoryClick(id) {
            this.categoryId = id;
            this.currentPage = 1;
            this.categoryShow = 1;
            this.loading = true;
            this.getHugeDealsList();
        }
    }
}
