<template>
    <div class="hugeDeals">
        <div class="hugeDealsMain">
            <!-- <div class="hugeDeals-title">
                <span>Home /</span>
                <span> Huge Deals</span>
            </div> -->
            <div class="hugeDeals-banner">
                <h5>HUGE DEALS</h5>
            </div>
            <div class="hugeDeals-main">
                <div class="main-nav">
                    <span :class="slectedId == '' ? 'ative' : ''" @click="slectedId = '';categoryClick(slectedId)">ALL</span>
                    <span v-for="(item, index) in classifyList" :key="index" :class="item.category_id == slectedId ? 'ative' : ''" @click="slectedId = item.category_id;categoryClick(item.category_id)">{{ item.category_name }}</span>
                </div>
                <div class="main-text">
                    <div class="text">
                        <span>{{total}} Product</span>
                        <p>Daily Drops is a limited-time event that may offer deeply promotional discounted items with limited quantities. Offer good while supplies last.</p>
                    </div>
                    <div>
                        <el-select v-model="value" placeholder="Select" @change="selectChange">
                            <el-option v-for="item in options" :key="item" :label="item.label" :value="item.value"> </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="main-list" v-loading="loading">
                    <div class="item-list">
                        <div class="item" v-for="(item, index) in hugeDealsList" :key="index">
                            <div class="item-img">
                                <div class="img">
                                    <el-image :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" fit="cover" style="cursor: pointer;"></el-image>
                                    <!-- <img :lazy-src="item.goods_image[0]" :src="item.goods_image[0]" /> -->
                                </div>
                                <span>SALE</span>
                                <button>ADD TO BAG</button>
                            </div>
                            <div class="item-text">
                                <span>{{ item.goods_name }}</span>
                                <!-- <img v-show="item.collection" src="@/assets/images/index/hds/xing1.png" @click="item.collection = false" />
                                <img v-show="!item.collection" src="@/assets/images/index/hds/xing.png" @click="item.collection = true" /> -->
                                <img src="@/assets/images/index/hds/xing.png" />
                            </div>
                            <div class="item-price">
                                <p>From {{ strCurXSoyego }}{{ item.price }}</p>
                                <span>Sale <b>{{ countrySoyego }}{{ strCurXSoyego }}{{ item.discount_price }}</b></span
                                >
                            </div>
                            <div class="item-color">
                                <div v-if="item.goods_spec_format != null && item.goods_spec_format[0].value.length > 0">
                                    <div @click="item.goods_spec_format[0].colorSoyego = item1.sku_id" v-for="(item1, index1) in item.goods_spec_format[0].value" :key="index1" :class="item.goods_spec_format[0].colorSoyego == item1.sku_id ? 'outer-round round-ative' : 'outer-round'">
                                        <div :class="'round round-' + item1.spec_value_name"></div>
                                    </div>
                                </div>
                                <div v-else></div>
                            </div>
                        </div>
                    </div>
                </div>
                <el-pagination style="margin-top: 30px;" background @current-change="handleCurrentChange" :current-page="currentPage" :page-size="pageSize" layout="prev, pager, next" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<style>
.about-content {
    background: #f7f9fa;
}
.hugeDeals .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #330000;
    color: #fff;
}
.hugeDeals .el-pagination.is-background .el-pager li:not(.disabled):hover {
    background: #330000;
    color: #fff;
}
.hugeDeals .el-pagination.is-background .btn-prev,
.hugeDeals .el-pagination.is-background .btn-next,
.hugeDeals .el-pagination.is-background .el-pager li {
    background: transparent;
    color: #330000;
}
.hugeDeals .hugeDealsMain .el-input__inner {
    border-radius: 0;
}
</style>
<style lang="scss" scoped>
@import "./css/hugeDeals.scss";
</style>

<script>
import hugeDeals from "./js/hugeDeals.js"

export default {
    name: "hugeDeals",
    components: {},
    mixins: [hugeDeals]
}
</script>